import React from 'react'
import {
    CSSTransition,
} from 'react-transition-group'
import { darken, desaturate, mix } from 'polished';
import styled from '@emotion/styled'
import { colors } from '../styles/colors'
import { css } from 'emotion'

const transitionTime = 1000;

const anim = css`
.todo-item {
    
}

.todo-item-enter {
    opacity: 0;
    transform: translateX(10%);
}

.todo-item-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: ${transitionTime}ms;
}

.todo-item-exit {
    opacity: 1;
    transform: translateX(0);
}

.todo-item-exit-active {
    opacity: 0;
    transform: translateX(-10%);
    transition: ${transitionTime}ms;
}
`

const Button = styled.button`
    display: inline-block;
    margin: 0 0 0 10px;
    padding: 0 20px;
    height: 51px;
    min-width: 100px;
    outline: none;
    color: ${colors.darkgrey};
    font-size: 1.7rem;
    line-height: 37px;
    font-weight: 400;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    /* background: linear-gradient(
        color(var(--green) whiteness(+7%)),
        color(var(--green) lightness(-7%) saturation(-10%)) 60%,
        color(var(--green) lightness(-7%) saturation(-10%)) 90%,
        color(var(--green) lightness(-4%) saturation(-10%))
    ); */
    background: linear-gradient(
        ${mix('0.1', '#fff', colors.lightgrey)},
        ${desaturate('0.1', darken('0.07', colors.lightgrey))} 60%,
        ${desaturate('0.1', darken('0.07', colors.lightgrey))} 90%,
        ${desaturate('0.1', darken('0.04', colors.lightgrey))}
    );
    border-radius: 5px;
    box-shadow: 0 0 0 1px inset rgba(0, 0, 0, 0.14);

    -webkit-font-smoothing: subpixel-antialiased;

    :active,
    :hover,
    :focus {
        /* background: color(var(--green) lightness(-9%) saturation(-10%)); */
        background: ${desaturate('0.1', darken('0.09', colors.green))};
        color: #fff;
    }
    @media (max-width: 500px) {
        margin: 10px 0 0;
        width: 100%;
    }
`;

const questions = [{
    title: 'Do any data subjects you are collecting data from, including your employees, reside in the EEA/EU?',
    subtitle: `If you are collecting data from citizens or employees that reside in EEA then GDPR applies to you, even if you are based in a country outside the EU.`,
},
{
    title: 'Is your organisation aware of what personal data means under the GDPR?',
    subtitle: `The GDPR's definition of personal data is ‘any information relating to an identified or identifiable natural person’. There is, however, a wide interpretation - it could mean a nickname, an ID number, an IP address or other indirect identification.`,
},
{
    title: 'Have you assessed the impact of the new definition of consent under the GDPR and how this affects your surveys?',
    subtitle: `GDPR’s revised approach means you must have clear documentation that the audience is happy for you to email them. And remember, you will need to obtain new consent from any current contacts in your database as well.`,
},
{
    title: 'Do you have a process for breach notification?',
    subtitle: `There will be a duty for all organisations to report certain types of data breaches and, in some cases, inform the individuals affected by the breach as well. `,
},
{
    title: 'Have you given the data subject the right to access his or her information?',
    subtitle: `Individuals must have the right to access any personal data that you store about them and this must be provided free of charge.`,
},
{
    title: 'Where a data subject has asked for his or her information, is the information given in a commonly useable and machine readable format?',
    subtitle: `When asked, you must use “reasonable means” to supply the information. For example, if the request is made electronically, you should provide the information in a commonly used electronic format.`,
},
{
    title: 'Does your organisation have the process of erasing the subject’s data at his/her request?',
    subtitle: `Make sure you have a process in place for when an individual asks you to delete their personal data. Would you know where to find the data, who has to give permission to delete it and what internal processes are in place to make sure that it happens?`,
},
{
    title: 'Does your organisation hold and process data only if it is absolutely necessary for the completion of its duties?',
    subtitle: `GDPR will introduce the concept of ‘privacy by design' and by default to encourage organisations to consider data protection throughout the entire life cycle of any process. Organisations will need to implement internal policies and procedures to be compliant.`,
},
{
    title: 'Have you trained your staff on the GDPR and how to properly handle data?',
    subtitle: `The majority of data breaches occur because of human error. To make sure staff are aware of their obligations, organisations are encouraged to implement GDPR staff awareness training and provide evidence that they understand the risks. `,
},
{
    title: 'Have you considered if you need to appoint a Data Protection Officer (DPO)?',
    subtitle: `For many businesses, it will be mandatory to appoint a DPO, for instance if your core activity involves the regular monitoring of individuals on a large scale.  You should consider now whether or not you need to appoint a DPO and to make sure they have the required expertise and knowledge.`,
},
];

export default class GDPRSurvey extends React.Component {
    state = {
        answers: [],
        pointer: 0,
        show: true,
        done: false,
    }

    setAnswer = (answer) => {
        const { answers, show } = this.state;
        const newAnswers = [...answers, answer];
        this.setState({ show: !show, answers: newAnswers });
    }

    nextQuestion = () => {
        const { answers } = this.state;
        let done = false;
        
        if (answers.length === questions.length || (answers[0] !== undefined && answers[0] === 0)) {
            done = true;
        }
        this.setState(state => ({
            ...state,
            show: true,
            done,
            pointer: state.answers.length,
        }));
    }

    resetSurvey = () => {
        this.setState({ show: false, restart: true, answers: [] })
    }

    onExited = () => {
        const { answers } = this.state;
        let done = false;
        
        if (answers.length === questions.length || (answers[0] !== undefined && answers[0] === 0)) {
            done = true;
        }
    }

    getResult = () => {
        const { answers } = this.state;

        // first answer NO? Then no problems...
        if (answers[0] === 0) {
            return (
                <div className="todo-item">
                    <h2>Excellent, you are not likely to be affected by the GDPR.</h2>
                    <p>Did you know that the GDPR does apply to your employees as well? So, if you collect any data on them, please feel free to begin the survey again if you would like to find out more about some of the issues. If not, it looks as if you are not likely to be affected by the GDPR.</p>
                    <Button type="button" onClick={this.resetSurvey}>
                        <span>RESTART SURVEY</span>
                    </Button>
                </div>)
        }

        // after that check the amount of No's...should be no more than 1
        const sum = answers.reduce((a, b) => a + b, 0);

        if (sum > 7) {
            return (
                <div className="todo-item">
                    <h2>Great, you seem to know a lot about GDPR. </h2>
                    <p>Nevertheless, it never hurts to speak to an expert. Feel free to contact one of LIZARD.global's consultants and get a full checkup of your services.</p>
                    <Button type="button" onClick={() => {
                        const win = window.open('https://lizard.global/contact', '_blank');
                        win.focus();
                    }}>
                        <span>GET EXPERT ADVICE</span>
                    </Button>
                </div>)
        }

        return (
            <div className="todo-item">
                <h2>You're not doing so well when it comes to GDPR.</h2>
                <p>You probably need to find out a bit more about the GDPR! All businesses must comply if they collect or store personal data, so it makes sense to be prepared.</p>
                <Button type="button" onClick={() => {
                    const win = window.open('https://lizard.global/contact', '_blank');
                    win.focus();
                }}>
                    <span>GET EXPERT ADVICE</span>
                </Button>
            </div>)
    }

    render() {
        const { pointer, show, done } = this.state;
        const question = questions[pointer];

        return (
            <div style={{
                    position: 'relative',
                    width: '100%',
                    height: 300,
                    backgroundColor: 'white',
                    marginBottom: 25,
                    padding: '40px 100px'
                }}
                className={anim}
            >
                <CSSTransition
                    timeout={transitionTime}
                    in={show}
                    classNames="todo-item"
                    unmountOnExit
                    onExited={this.nextQuestion}
                >
                    {!done ?
                        <div key={`item_${pointer}`} className="todo-item">
                            <h2>{`${pointer + 1}. ${question.title}`}</h2>
                            <p>{question.subtitle}</p>
                            <div>
                                <Button type="button" onClick={() => this.setAnswer(1)}>
                                    <span>YES</span>
                                </Button>
                                <Button type="button" onClick={() => this.setAnswer(0)}>
                                    <span>NO</span>
                                </Button>
                            </div>
                        </div>
                    :
                        this.getResult()
                    }
                </CSSTransition>
            </div>
        )
    }
}